html,
body,
#root {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

header {
  background-color: #409dd8;
}

.logo img {
  height: 50px;
  max-width: 100%;
  max-height: 100%;
}

.body {
  text-align: center;
}

.container {
  width: 600px;
}

.feed {
  display: inline-flex;
  text-align: left;
  margin-top: 12px;
  border: 1px black solid;
}

footer {
  min-height: 48px;
  background-color: #409dd8;
  margin-top: auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* UTILITIES */

.w-full {
  width: 100%;
}
